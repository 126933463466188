import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';
import QueueService from './QueueService';

const ConfirmEntryModal = ({ onClose, onConfirm, token }) => {
  const [tableNo, setTableNo] = useState('');
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleConfirm = async () => {
    setLoading(true); // Set loading to true when API call starts
    try {
      await onConfirm(selectedTableId, tableNo);
    } finally {
      setLoading(false); // Set loading to false when API call ends
    }
  };

  const fetchTables = async (token) => {
    const res = await QueueService.fetchTables(token);
    console.log(res);
    setTables(res);
  };

  useEffect(() => {
    if (token) {
      fetchTables(token);
    }
  }, [token]);

  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
        <h2>Confirm Entry</h2>
        <p>Are you sure you want to mark this user as entered?</p>
        <div className="form-group">
          {tables && tables.length > 0 ? (
            <>
              <label>Select Table (optional):</label>
              <select
                value={selectedTableId || ''}
                onChange={(e) => setSelectedTableId(parseInt(e.target.value, 10))}
                className="form-control"
              >
                <option value="">None</option>
                {tables.map((table) => (
                  <option key={table.id} value={table.id}>
                    {table.name} (Capacity: {table.capacity}, Status: {table.status})
                  </option>
                ))}
              </select>
            </>
          ) : (
            <>
              <label>Table No (optional):</label>
              <input
                type="text"
                value={tableNo}
                onChange={(e) => setTableNo(e.target.value)}
                className="form-control"
              />
            </>
          )}
        </div>
        <div className="actions">
          <button onClick={onClose} className="btn cancel-button">Cancel</button>
          {/* Disable button when loading is true and change text to 'Marking Entry...' */}
          <button onClick={handleConfirm} className="btn confirm-button" disabled={loading}>
            {loading ? 'Marking Entry...' : 'Confirm'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmEntryModal;
