import React, { useState, useEffect, useRef } from 'react';
import QueueService from './QueueService';
import './AllEnteredPage.css';
import { FaEdit, FaPlus} from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi'; 
import UserForm from './UserForm';
import Modal from './Modal';
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;


const AllEnteredPage = ({token, business_id}) => {
  const [customers, setCustomers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [queue_info, setQueueInfo] = useState([]);
  const [exitTime, setExitTime] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [queue_auto, setQueueAutoSelect] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [selectedColumn, setSelectedColumn] = useState('');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');
  const [tempValue, setTempValue] = useState('');
  const [tables, setTables] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedRange, setSelectedRange] = useState('today');
  const filterDropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const highlightText = (text, query) => {
    if (!query) return text;
  
    const parts = text.toString().split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query ? <mark key={index}>{part}</mark> : part
    );
  };
  
  const filteredCustomers = customers.filter((customer) =>
    Object.values(customer).some((value) => 
      value != null && value.toString().toLowerCase().includes(searchQuery)
    )
  );
  

  const fetchTables = async (token) => {
    const res = await QueueService.fetchTables(token);
    console.log(res)
    setTables(res);
};

 // Function to handle clicks outside the dropdown
 useEffect(() => {
  const handleClickOutside = (event) => {
    if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
      setShowFilters(false); // Close the dropdown
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [filterDropdownRef]);
  
  const startEditing = (index, field, currentValue) => {
    setEditingIndex(index);
  };


  const fetchBusinessInfo = async (business_id) => {
    await QueueService.GetBusinessInfo(business_id)
      .then(response => {
        if (response.queue_info && response.queue_info.length > 0) {
          setQueueInfo(response.queue_info);
          setQueueAutoSelect(response.business_metadata.automatic_queue_selection);
        }
      })
      .catch(console.error);
  };

 

  const handleCancel = () => {
    setEditingIndex(null);
    setTempValue('');
  };

  const handleAddUser = () => {
    setShowForm(true);
  };

  const handleFormSubmit = async (user) => {
    const userPayload = {
      ...user,
      business_id,
    };
    const newUser = await QueueService.AddUser(userPayload, token);
    fetchCustomers();
    setShowForm(false);
  };


  const downloadCSV = () => {
    const headers = [
      "S.No",
      "Name",
      "Phone",
      "People",
      "Table Seating",
      "Status",
      "Table No",
      "Initial Waiting Number",
      "Total Waiting Time(mins)",
      "Arrived At",
      "Entry Time",
      "Delete Time",
      "Exit Time",
      "Notes",
      "Deleted Reason"
    ];
  
    // Prepare the CSV content
    const csvContent = customers.map((customer, index) => {
      const notesText = customer.notes
        ? customer.notes.map(note => `${formatNoteTime(note.note_created_at)}: ${note.note_text}`).join(" | ")
        : "";
  
      const totalWaitingTime = Math.floor(
        (new Date(customer.entry_time) - new Date(customer.created_at)) / 1000 / 60
      );
  
      return [
        index + 1,
        customer.name,
        customer.phone,
        customer.no_of_people,
        customer.queue_name,
        customer.status,
        customer.table_no || "",
        Math.round(customer.initial_waiting_number) || "",
        totalWaitingTime || "",
       `"${formatDateTime(customer.created_at)}"`,
      `"${formatDateTime(customer.entry_time)}"`,
      `"${formatDateTime(customer.delete_time)}"`,
        customer.exit_time ? `"${formatDateTime(customer.exit_time)}"`: "",
        notesText,
        customer.removed_reason || ""
      ].join(",");
    });
    const csvData = [headers.join(","), ...csvContent].join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "customers_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };


  const getEpochTimeRange = (rangeType) => {
    const now = new Date();
    let startTime, endTime;
  
    if (rangeType === 'today') {
      startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000;
      endTime = startTime + (24 * 3600);
    } else if (rangeType === 'yesterday') {
      const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      startTime = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()).getTime() / 1000;
      endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000;
    } else if (rangeType === 'custom') {
      startTime = new Date(customStartDate).getTime() / 1000;
      endTime = new Date(customEndDate).getTime() / 1000;
    }
  
    return { startTime, endTime };
  };



  const fetchCustomers = () => {
    const { startTime, endTime } = getEpochTimeRange(selectedRange);
    
    // Construct query parameters
    const params = {
      start_time: startTime,
      end_time: endTime
    };
    
    QueueService.CustomerInfo(params, token)
      .then(response => {
        if (response) {
          setCustomers(response);
        } else {
          console.error('Failed to fetch customer info:', response.errors);
        }
      })
      .catch(console.error);
  }; 

  useEffect(() => {
    console.log(token);
    fetchCustomers(); // Fetch customers on initial load and whenever selectedRange changes
  }, [selectedRange, customStartDate, customEndDate]);


  useEffect(() => {
    if (token) {
      fetchTables(token);
      fetchBusinessInfo(business_id);
    }
  }, [token, business_id]); // Adding dependencies ensures the effect runs when these values change

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    
    const date = new Date(dateTimeString);
    
    // Convert the UTC time to IST by adding 5 hours and 30 minutes
    const offsetInMinutes = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
    const istDate = new Date(date.getTime() + offsetInMinutes * 60000);
  
    return istDate.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit'
    });
  };



  const handleExitTimeChange = (event, index) => {
    setExitTime(event.target.value);
    setEditingIndex(index);
};



  const formatExitTimeForEditing = (exitTime) => {
    if (!exitTime) return '';
    
    const utcDate = new Date(exitTime);
    const offsetInMinutes = 5 * 60 + 30;
    const istDate = new Date(utcDate.getTime() + offsetInMinutes * 60000);
    
    return istDate.toISOString().slice(0, 16);
};




const handleExitTimeBlur = (customer) => {
  if (exitTime) {
      const istDate = new Date(exitTime);
      const offsetInMinutes = 5 * 60 + 30;
      const utcDate = new Date(istDate.getTime());
      const exitTimeUnix = Math.floor(utcDate.getTime() / 1000);

      const updatedCustomers = customers.map((c, idx) => {
          if (idx === editingIndex) {
              return { ...c, exit_time: utcDate.toISOString() };
          }
          return c;
      });
      setCustomers(updatedCustomers);
      setEditingIndex(null);

      const data = {
          mapping_id: customer.mapping_id,
          exit_time: exitTimeUnix
      };

      fetch(`${BASE_URL}/update_mapping`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
          console.log('Exit time updated successfully:', data);
      })
      .catch(error => {
          console.error('Error updating exit time:', error);
      });
  }
};

const formatNoteTime = (noteCreatedAt) => {
  const utcDate = new Date(noteCreatedAt);
  const offsetInMinutes = 5 * 60 + 30;
  const istDate = new Date(utcDate.getTime() + offsetInMinutes * 60000);
  
  return istDate.toLocaleTimeString('en-IN', {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
  });
};

  const handleEditChange = (event, index, column) => {
    const value = event.target.value;
    setEditValue(value);
  setEditingIndex(index);
  setSelectedColumn(column);
};

const handleSubmit = async (customer, field, newStatus) => {
  console.log('New status:', tempValue);
  try {
    let apiUrl;
    let apiPayload;

    if (field === 'status') {
      if (newStatus === 'entered') {
        // When changing status to 'entered', call the `update_mapping` API with `mark_as_active: true`
        apiUrl = `${BASE_URL}/update_mapping`;
        apiPayload = {
          mapping_id: customer.mapping_id,
          mark_as_entered: true,
        };
      } else if (newStatus === 'active') {
        // When changing status to 'active', call the `add_or_update_user` API with customer as the payload
        console.log('in add or update user');
        console.log(customer);
        apiUrl = `${BASE_URL}/add_or_update_user`;
        apiPayload = {
          // Include the relevant customer data as the payload
          ...customer,
        };
      }
    }

    // Call the respective API
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(apiPayload),
    });

    const data = await response.json();
    if (response.ok) {
      console.log(`${field} updated successfully:`, data);
    } else {
      console.error(`Error updating ${field}:`, data);
    }

    // Update the UI after successful API call
    const updatedCustomers = customers.map((c, idx) => {
      if (idx === editingIndex) {
        return { ...c, status: tempValue };
      }
      return c;
    });

    setCustomers(updatedCustomers);
    setEditingIndex(null);
    setTempValue('');
  } catch (error) {
    console.error(`Error updating ${field}:`, error);
  }
};

const handleStatusChange = (customer, newStatus) => {
  console.log('here');
  console.log('New status:', newStatus);
  
  setTempValue(newStatus);
  handleSubmit(customer, 'status', newStatus );
};



// const handleStatusChange = (customer, newStatusColumn) => {
//   const updatedCustomers = customers.map((c, idx) => {
//     if (idx === editingStatusIndex) {
//       return { ...c, status: newStatusColumn === 'mark_as_entered' ? 'entered' : 'active' };
//     }
//     return c;
//   });
//   setCustomers(updatedCustomers);
//   setEditingStatusIndex(null);

//   const data = {
//     mapping_id: customer.mapping_id,
//     [newStatusColumn]: true,
//   };

//   fetch(`${BASE_URL}/update_mapping?auth_token=${token}`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     credentials: 'include',
//     body: JSON.stringify(data),
//   })
//     .then(response => response.json())
//     .then(data => {
//       console.log('Status updated successfully:', data);
//     })
//     .catch(error => {
//       console.error('Error updating status:', error);
//     });
// };



const handleEditBlur = (customer) => {
  if (editValue) {
    const updatedCustomers = customers.map((c, idx) => {
      if (idx === editingIndex) {
        return { ...c, [selectedColumn]: editValue };
      }
      return c;
    });
    setCustomers(updatedCustomers);
    setEditingIndex(null);

    
    const oldTableId = customer.table_id || null;  // Use null if no previous table was selected
    const newTableId = tables.find((table) => table.name === editValue)

    const data = {
      mapping_id: customer.mapping_id,
      old_table_id: oldTableId,
      table_id: newTableId.id,
      [selectedColumn]: editValue
    };

    fetch(`${BASE_URL}/update_mapping`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        console.log(`${selectedColumn} updated successfully:`, data);
      })
      .catch(error => {
        console.error(`Error updating ${selectedColumn}:`, error);
      });
  }
};
const formatColumnContent = (customer, column) => {
  if (column === 'exit_time') {
    return customer.exit_time ? formatDateTime(customer.exit_time) : '';
  }
  return customer[column] || '_';
};


  return (
    
    <div className="all-entered-page">
      
      <div className="toolbar">

<div className="filter-dropdown" ref={filterDropdownRef}>

<div className="search-container">
<input
  type="text"
  placeholder="🔍  &nbsp; Search..."
  value={searchQuery}
  onChange={handleSearchChange}
  className="search-input"
/>
</div>

<div className="filter-button-container">
  <button className="filter-button" onClick={() => setShowFilters(!showFilters)}>
    {selectedRange === 'custom'
      ? `${customStartDate || 'Start Date'} - ${customEndDate || 'End Date'}`
      : selectedRange.charAt(0).toUpperCase() + selectedRange.slice(1)}
    <span className="arrow-down-filter">▼</span> {/* Downward arrow */}
  </button>
  

  {showFilters && (
    <div className="filter-options">
      <button
        onClick={() => {
          setSelectedRange('today');
          setShowFilters(false); // Close dropdown after selection
        }}
      >
        Today
      </button>
      <button
        onClick={() => {
          setSelectedRange('yesterday');
          setShowFilters(false); // Close dropdown after selection
        }}
      >
        Yesterday
      </button>
      <button
        onClick={() => {
          setSelectedRange('custom');
          // Keep dropdown open for custom date selection
        }}
      >
        Custom
      </button>

      {/* Display custom date pickers when "Custom" is selected */}
      {selectedRange === 'custom' && (
        <div className="custom-date-picker">
          <input 
            type="date" 
            value={customStartDate} 
            onChange={(e) => setCustomStartDate(e.target.value)} 
          />
          <input 
            type="date" 
            value={customEndDate} 
            onChange={(e) => setCustomEndDate(e.target.value)} 
          />
        </div>
      )}
       
    </div>
  )}
 


</div>
  </div>

    <div className="download-csv" onClick={downloadCSV} style={{ cursor: 'pointer' }}>
    < span className='icon-circle'><FiDownload /> </span>  {/* Downward arrow icon */}
  </div>
</div>
{/* <div>
  <p>Total Entries: {filteredCustomers.length}</p>
  <p>Total Entered: {filteredCustomers.filter(customer => customer.status === 'entered').length}</p>
  <p>Total Inactive: {filteredCustomers.filter(customer => customer.status === 'inactive').length}</p>
  <p>Total Number of People: {filteredCustomers.reduce((total, customer) => total + customer.no_of_people, 0)}</p>
  <p>Total Number of People Entered: {filteredCustomers.filter(customer => customer.status === 'entered').reduce((total, customer) => total + customer.no_of_people, 0)}</p>
  <p>Total Number of Inactive People: {filteredCustomers.filter(customer => customer.status === 'inactive').reduce((total, customer) => total + customer.no_of_people, 0)}</p>
  <p>Total Entered Between 12 PM and 6:30 PM: {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             ((hour >= 12) ) && // Includes from 12:00 PM
             (hour < 18 || (hour === 18 && minute < 30)); // Excludes 5:30 PM onwards
    }).length
  }</p>
  <p>Total Number of People Entered Between 12 PM and 6:30 PM: {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             ((hour >= 12)) && // Includes from 12:00 PM
             (hour < 18 || (hour === 18 && minute < 30)); // Excludes 5:30 PM onwards
    }).reduce((total, customer) => total + customer.no_of_people, 0)
  }</p>

<p>Total Entered after 6:30 PM {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             (hour > 18 || (hour === 18 && minute > 30)); // Excludes 5:30 PM onwards
    }).length
  }</p>
  <p>Total Number of People Entered after 6:30 PM: {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             (hour > 18 || (hour === 18 && minute > 30)); // Excludes 5:30 PM onwards
    }).reduce((total, customer) => total + customer.no_of_people, 0)
  }</p>
</div> */}

<div>
  <p style={{margin: '10px 0px 0px 10px'}}>
    total {filteredCustomers.filter(customer => customer.status === 'entered').length} enteries, 
    
    {filteredCustomers.filter(customer => customer.status === 'entered').reduce((total, customer) => total + customer.no_of_people, 0)} pax,
    lunch:
    {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             ((hour >= 4) ) && // Includes from 9:30 AM IST
             (hour < 13) // time in utc || (hour === 18 && minute < 30)); // Excludes 6:30 PM ist onwards
    }).length
  } enteries, 
  {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             ((hour >= 4)) && // Includes from 9:30 AM ist
             (hour < 13) // || (hour === 18 && minute < 30)); // Excludes 6:30 PM ist onwards
    }).reduce((total, customer) => total + customer.no_of_people, 0)
  } pax

  Dinner:
  {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             (hour >= 13 ) //|| (hour === 18 && minute > 30)); //  6:30 PM ist onwards
    }).length
  } enteries,
  {
    filteredCustomers.filter(customer => {
      const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
      const hour = entryTime.getHours();
      const minute = entryTime.getMinutes();
      return customer.status === 'entered' && 
             (hour >= 13) // || (hour === 18 && minute > 30)); //  6:30 PM ist onwards
    }).reduce((total, customer) => total + customer.no_of_people, 0)
  } pax

  </p>
</div>
      

      <table className="customers-table">
        <thead>
          <tr>
            <th>S.No</th> 
            <th>Name</th>
            <th>Table No <FaEdit /></th>
            <th>Status <FaEdit /></th>
            <th>Phone</th>
            <th>People</th>
            <th>Table Seating</th>
            <th>Initial Waiting Number</th>
            <th>Total Waiting Time(mins)</th>
            <th style={{ minWidth: '150px' }}>Arrived At</th>
            <th style={{ minWidth: '150px' }} >Entry Time</th>
            <th style={{ minWidth: '150px' }} >Delete Time</th>
            <th style={{ minWidth: '150px' }} >Exit Time <FaEdit /></th>
            <th>Notes</th>
            <th>Deleted Reason <FaEdit /></th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomers.map((customer, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{highlightText(customer.name, searchQuery)}</td>
              <td>
          {customer.status === 'entered' && index === editingIndex && selectedColumn === 'table_no' ? (
            tables && tables.length > 0 ? (
              <select
                value={editValue}
                onChange={(event) => handleEditChange(event, index, 'table_no')}
                onBlur={() => handleEditBlur(customer)}
                className="table-dropdown"
              >
                <option value="">Select a table</option>
                {tables.map((table) => (
                  <option key={table.id} value={table.name}>
                    {table.name} (Status: {table.status})
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                value={editValue}
                onChange={(event) => handleEditChange(event, index, 'table_no')}
                onBlur={() => handleEditBlur(customer)}
              />
            )
          ) : (
            <span
              onClick={() => {
                setEditingIndex(index);
                setSelectedColumn('table_no');
                setEditValue(customer.table_no || '');
              }}
              className="editable"
            >
              {formatColumnContent(customer, 'table_no')}
            </span>
          )}
        </td>

        <td>
              {index === editingIndex && selectedColumn === 'status' ? (
                <>
                  {customer.status === 'inactive' && (
                    <>
                      <button onClick={() => handleStatusChange(customer, 'active')}>Mark Active in Queue</button>
                      <button onClick={() => handleStatusChange(customer, 'entered')}>Mark as Entered</button>
                    </>
                  )}
                  
                  {customer.status === 'entered' && (
                    <button onClick={() => handleStatusChange(customer, 'active')}>Mark Active in Queue</button>
                  )}
                  {customer.status === 'active' && (
                    <button onClick={() => handleStatusChange(customer, 'entered')}>Mark as Entered</button>
                  )}
                  <button onClick={handleCancel}>Cancel</button>
                </>
              ) : (
                <span
                  onClick={() => {
                    
                    startEditing(index, 'status', customer.status);
                    setSelectedColumn('status');
                  }}
                  className="editable"
                >
                  {highlightText(customer.status, searchQuery)}
                </span>
              )}
            </td>

              <td>{highlightText(customer.phone, searchQuery)}</td>
              <td>{customer.no_of_people}</td>
              <td>{highlightText(customer.queue_name, searchQuery)}</td>
              
        
              <td>{customer.initial_waiting_number ? Math.round(customer.initial_waiting_number) : ''}</td>
              <td>
            {Math.max(0, Math.floor((new Date(customer.entry_time) - new Date(customer.created_at)) / 1000 / 60))}
          </td>
            <td>{formatDateTime(customer.created_at)}</td>
              <td>{formatDateTime(customer.entry_time)}</td>
              <td>{formatDateTime(customer.delete_time)}</td>
              <td>
              {customer.status === 'entered' && index === editingIndex ? (
                  <input
                      type="datetime-local"
                      value={exitTime}
                      onChange={(event) => handleExitTimeChange(event, index)}
                      onBlur={() => handleExitTimeBlur(customer)}
                      className="exit-time-input"
                  />
              ) : (
                  <span
                      onClick={() => {
                          if (customer.status === 'entered') {
                              setEditingIndex(index);
                              setExitTime(customer.exit_time ? formatExitTimeForEditing(customer.exit_time) : '');
                          }
                      }}
                      className={customer.status === 'entered' ? 'editable' : ''}
                  >
                      {customer.exit_time ? formatDateTime(customer.exit_time) : '_'}
                  </span>
              )}
          </td>
          
          <td>
                {customer.notes && customer.notes.length > 0 && (
                    customer.notes.map((note, idx) => (
                        <div key={note.note_id}>
                            {formatNoteTime(note.note_created_at)}: {note.note_text}
                            {idx < customer.notes.length - 1 && ' | '}
                        </div>
                    ))
                )}
            </td>
              <td>{customer.removed_reason}</td>
                          </tr>
                        ))}
        </tbody>
      </table>
      {showForm && ( <Modal onClose={() => setShowForm(false)}> <UserForm onSubmit={handleFormSubmit} queue_info={queue_info} auto_queue={queue_auto}usercheck={false} isPhoneRequired={false} business_id={business_id}/> </Modal> )}
      <button onClick={handleAddUser} className="add-button-all-customers">
        <FaPlus size={20} />
      </button>
    </div>
  );
};



export default AllEnteredPage;
